import Head from 'next/head';
import { useRouter } from 'next/router';
import { styled, useTheme } from '@mui/material/styles';
import { Theme, useMediaQuery } from '@mui/material';

import type { Testimonial, AverageScore } from 'lib/ekomi/types';
import { fetchTestimonials } from 'lib/ekomi/fetchTestimonials';
import { fetchAverageScore } from 'lib/ekomi/fetchAverageScore';

import { MetaHead } from 'components/MetaHead/MetaHead';
import { HeaderWithOptions } from 'components/Header';
import { Footer } from 'components/Footer';
import StepExplanations from 'components/StepExplanations';
import Advantages from 'components/Advantages';
import { Testimonials } from 'components/Testimonials';
import FAQ from 'components/FAQ';
import { PartnerBanks } from 'components/PartnerBanks/PartnerBanks';
import SEOLinks from 'components/SEOLinks';
import { ImageWithCTA } from 'components/ImageWithCTA';
import BottomNavigationMap from 'components/BottomNavigation/BottomNavigationMap';
import TextImage from 'components/TextImage';

import { steps } from 'legacy/data/stepExplanations';
import { advantages } from 'legacy/data/advantages';
import { textImageObject } from 'legacy/data/textImageObject';
import { imageWithCTAObject } from 'legacy/data/imageWithCTAObject';
import { seoLinksObject } from 'legacy/data/SEOLinksObject';
import { faqEntries } from 'legacy/data/faqObject';

import { useDecision, withOptimizelyProps } from 'lib/optimizely';
import { Stage } from 'components/Stage/Stage';
import { ListIcons, SellingPointsPosition } from 'components/Stage/utils';
import { getStyleOverrides } from 'components/Stage/StyleOverrides/SEAPages';
import { ABTestKeys } from 'lib/optimizely/flagConfigs';
import { LINKED_DATA_SCHEMA } from 'lib/constants/jsonld';

interface PageProps {
  testimonials: Testimonial[];
  averageScore: AverageScore | null;
}

const StageBackground = styled('div')(({ theme }) => ({
  background: `linear-gradient(0deg, #f7f7f7 0%, rgba(247, 247, 247, 0) 100%)`,
  padding: `${theme.spacing(3)} 0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(8)} 0 ${theme.spacing(4)}`,
  },
}));

export default function Home({ testimonials, averageScore }: PageProps) {
  const theme = useTheme();
  const { pathname } = useRouter();
  const canonicalUrl = `https://www.smava.de${pathname}`;

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    { noSsr: true },
  );

  const overrideAttributes = {
    overrideAttributes: {
      device: isMobile ? 'mobile' : 'desktop',
    },
  };

  const [decisionCAT2766AppRedirectionABCD] = useDecision(
    ABTestKeys.CAT2766_app_redirectionABCD,
    undefined,
    { ...overrideAttributes },
  );

  const appRedirectionVariation =
    decisionCAT2766AppRedirectionABCD.variationKey;

  return (
    <>
      <MetaHead
        follow={true}
        index={true}
        markupSchema={LINKED_DATA_SCHEMA.website}
      />
      <Head>
        {process.env.RELEASE_STAGE === 'live' && (
          <link href={canonicalUrl} rel="canonical" />
        )}
      </Head>
      <HeaderWithOptions hasLoginLink hasNavigationMenu hasPhoneBox={true} />
      <StageBackground>
        <Stage
          appRedirectionVariation={appRedirectionVariation}
          sellingPoint1="Persönliche Angebote von über 20 Banken"
          sellingPoint2="SCHUFA-neutral und 100% kostenlos"
          sellingPoint3="Deutschlands bekanntestes** Kreditportal"
          sellingPointsPosition={SellingPointsPosition.UP}
          listIcon={ListIcons.CHECK_MARKS}
          averageScore={averageScore}
          styleOverrides={getStyleOverrides(theme)}
          showGuaranteeBadgeOnTop
          showTrustAndEkomi
          showTvBanner
          isBulletPointsPositionRearranged
        />
      </StageBackground>

      {appRedirectionVariation !== 'variation_d' && <PartnerBanks />}

      <Testimonials
        testimonials={testimonials}
        title="Über 300.000 zufriedene Kunden mit smava"
      />
      <StepExplanations
        name="stepExplanations"
        headline="In 3 Schritten zum Wunschkredit"
        //@ts-ignore
        steps={steps}
      />
      {/* @ts-ignore */}
      <TextImage {...textImageObject} />
      {/* @ts-ignore */}
      <Advantages {...advantages} />

      <FAQ
        name="Die häufigsten Fragen zum Kreditvergleich"
        headline="Die häufigsten Fragen zum Kreditvergleich"
        faqEntries={faqEntries}
        buttonUrl="https://smava.zendesk.com/hc/de"
        buttonText="Zum Hilfe Center"
      />
      {/* @ts-ignore */}
      <SEOLinks {...seoLinksObject} moreInfo />
      {/* @ts-ignore */}
      <ImageWithCTA {...imageWithCTAObject} />
      <BottomNavigationMap />
      <Footer />
    </>
  );
}

export const getServerSideProps = withOptimizelyProps<PageProps>(async () => {
  const testimonials = await fetchTestimonials(24);
  const averageScore = await fetchAverageScore(365);

  return {
    props: {
      testimonials,
      averageScore,
    },
  };
});
